<template>
    <div>
        <div class="cirle-box" :style="`border-color:${borderCol}`">
            <div class="indicator" ref="indicator" :style="`border-color:${borderCol}`">
                <span  class="text">{{ completion }}% </span>
                <!-- for="completion" --> 
                <div class="ball" :style="`top: ${topValue}%;background-color:${borderCol};`">
                </div>        
            </div>
        </div>
        <div style="text-align: center;color: #A8ABB2;">{{ title}}</div>
    </div>
</template>
<script>
export default {
    name: 'Ball',
    components: {

    },
    mixins: [],
    props: {
        completion: {
            type: Number,
            default: 0
        },
        borderCol:{
            type: String,
            default: '#67C23A'
        },
        index:{
            type: Number,
            default: 0
        },
        title:{
            type:String,
            default:'123'
        }
    },
    computed:{
        topValue(){
            return 100 -this.completion;
        }
    },
    data() {
        return {

        }
    },
    watch: {
    },
    mounted() {
    },
    methods: {
       
    }
};
</script>
<style lang='less' scoped>
.cirle-box{
    --completion: 30%;
    --offset: 20px;
    --waterBck:#67C23A;
    --borderCol:#67C23A;
    // padding: 5px;
    border-radius: 50%;
    border: 1px solid var(--borderCol);
    width: 74px;
    height: 74px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.indicator {
   
    height: 65px;
    width: 65px;
    position: relative;
    border-radius: 40px;
    border: 2px solid var(--borderCol);
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-left: 10px;
    // background: white;

    .ball {
        display: block;
        width: 200px;
        height: 200px;
        content: "";
        background-color: var(--waterBck);
        // border-radius: 40%;
        position: absolute;
        left: 50%;
        top: calc(100% - var(--completion) + var(--offset));
        translate: -50% 0;
        -webkit-animation: spin 5s linear infinite;
        animation: spin 8s linear infinite;
        transition: all 1s;
    }
}

output {
    z-index: 1;
    font-family: system-ui;
    font-size: 20px;
    color: #333;
}

// .indicator

// @-webkit-keyframes spin {
//     100% {
//         rotate: 360deg;
//     }
// }

// @keyframes spin {
//     100% {
//         rotate: 360deg;
//     }
// }
.text{
    z-index: 2;
    color: #fff;
    text-align: center;
    // font-family: "Barlow";
    font-size: 15px;
    font-weight: normal;
    line-height: 36px;
    filter: invert(100%);
}


</style>
